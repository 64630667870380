body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Color Theme Swatches in Hex */
.My-Color-Theme-1-hex { color: #88BB44; }
.My-Color-Theme-2-hex { color: #C0E195; }
.My-Color-Theme-3-hex { color: #9EDB4F; }
.My-Color-Theme-4-hex { color: #4E5C3D; }
.My-Color-Theme-5-hex { color: #7AA83D; }

/* Color Theme Swatches in RGBA */
.My-Color-Theme-1-rgba { color: rgba(136, 187, 68, 1); }
.My-Color-Theme-2-rgba { color: rgba(192, 225, 149, 1); }
.My-Color-Theme-3-rgba { color: rgba(158, 219, 79, 1); }
.My-Color-Theme-4-rgba { color: rgba(78, 92, 61, 1); }
.My-Color-Theme-5-rgba { color: rgba(122, 168, 61, 1); }

/* Color Theme Swatches in HSLA */
.My-Color-Theme-1-hsla { color: hsla(85, 46, 50, 1); }
.My-Color-Theme-2-hsla { color: hsla(86, 55, 73, 1); }
.My-Color-Theme-3-hsla { color: hsla(86, 66, 58, 1); }
.My-Color-Theme-4-hsla { color: hsla(87, 20, 30, 1); }
.My-Color-Theme-5-hsla { color: hsla(85, 46, 44, 1); }

